import { CommonModule } from '@angular/common';
import {
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import {
  FormBuilder,
  FormGroupDirective,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { ButtonModule } from 'primeng/button';
import { Subject, Subscription, takeUntil } from 'rxjs';
import { ReturnService } from '../../services/return-services/return.service';
import { UserService } from '../../services/user-service/user.service';
import { DateSelectorComponent } from '../../shared/components/date-selector/date-selector.component';
import { GridComponent } from '../../shared/components/grid/grid.component';
import { PaginatorComponent } from '../../shared/components/paginator/paginator.component';
import { TabFilterComponent } from '../../shared/components/tab-filter/tab-filter.component';
import returnHeader from './header-column';
@Component({
  selector: 'rcp-return-metrics',
  standalone: true,
  imports: [
    CommonModule,
    ButtonModule,
    PaginatorComponent,
    GridComponent,
    FormsModule,
    TabFilterComponent,
    ReactiveFormsModule,
    DateSelectorComponent,
  ],
  templateUrl: './return-metrics.component.html',
  styleUrl: './return-metrics.component.scss',
  encapsulation: ViewEncapsulation.None,
})
export class ReturnMetricsComponent implements OnInit, OnDestroy {
  @ViewChild(GridComponent) childComponent!: GridComponent;
  @ViewChild(FormGroupDirective) form2: FormGroupDirective;
  destroy$: Subject<boolean> = new Subject<boolean>();
  gridData: any[] = [];
  summaryViewData = [];
  parentRowField: {} = {};
  minDate: Date;
  maxDate: Date;
  startDate: Date;
  endDate: Date;
  selectedDates: Date[] = [];
  maxDateOfFirstSelectionDate: Date | undefined;
  totalCount: any;
  headerColumns: any;
  totalRecords: number = 0;
  first: number = 0;
  rows: number = 5;
  offSet: number = 0;
  companyname: string;
  isClearSearchData: boolean = false;
  submitted: boolean = false;
  selectedWareHouses = '';
  subscription: Subscription;
  placeholderValue: string = 'Enter a date range';
  passGridName: string = 'Return list';
  pageLimit: string = '150';
  breadcrum_welcome: string = 'Welcome';
  breadcrum_return: string = 'Return';
  titleName: string = 'Return details';
  validation_rma: string = 'RMA Number is required.';
  validation_orderid: string = 'Client Order ID is required.';
  validation_daterange: string = 'From and To date are required';
  resetFilters: string = 'Reset filters';
  applyFilters: string = 'Apply filters';
  requestBody: { query: string; variables: any; operationName: string };
  searchForm = this.fb.group({
    searchByOrderId: ['', Validators.required],
    searchByClientOrderId: ['', Validators.required],
    dateRange: [null, [Validators.required]],
  });
  constructor(
    public fb: FormBuilder,
    public returnService: ReturnService,
    public cd: ChangeDetectorRef,
    public userService: UserService
  ) {}
  ngOnInit() {
    this.companyname = sessionStorage.getItem('companyname');
    this.selectedWareHouses = JSON.stringify(
      sessionStorage.getItem('selectedWareHouses')?.split(',')
    );
    this.gridHeaderData();
    this.setupValueChangeListeners();
  }
  onPageChange(event?: any) {
    if (event) {
      const start = event.first;
      const end = event.first + event.rows;
      this.childComponent.handleParentEvent(start, end);
    } else {
      this.childComponent.handleParentEvent(this.first, this.rows);
    }
  }
  setupValueChangeListeners() {
    this.searchForm.get('searchByOrderId')?.valueChanges.subscribe((value) => {
      if (value) {
        this.clearOtherFields('searchByOrderId');
        this.isClearSearchData = true;
        this.cd.detectChanges();
      }
      this.resetValidation();
    });
    this.searchForm
      .get('searchByClientOrderId')
      ?.valueChanges.subscribe((value) => {
        if (value) {
          this.clearOtherFields('searchByClientOrderId');
          this.isClearSearchData = true;
          this.cd.detectChanges();
        }
        this.resetValidation();
      });
    this.searchForm.get('dateRange')?.valueChanges.subscribe((value) => {
      if (value) {
        this.clearOtherFields('dateRange');
      }
      this.resetValidation();
    });
  }
  resetValidation() {
    this.submitted = false;
  }
  clearOtherFields(changedField: string) {
    switch (changedField) {
      case 'searchByOrderId':
        this.searchForm
          .get('searchByClientOrderId')
          ?.setValue('', { emitEvent: false });
        this.searchForm.get('dateRange')?.reset(null, { emitEvent: false });
        break;
      case 'searchByClientOrderId':
        this.searchForm
          .get('searchByOrderId')
          ?.setValue('', { emitEvent: false });
        this.searchForm.get('dateRange')?.reset(null, { emitEvent: false });
        break;
      case 'dateRange':
        this.searchForm
          .get('searchByOrderId')
          ?.setValue('', { emitEvent: false });
        this.searchForm
          .get('searchByClientOrderId')
          ?.setValue('', { emitEvent: false });
        break;
    }
  }
  searchFormSubmit(formDirective: FormGroupDirective) {
    this.submitted = true;
    const searchByOrderId = {
      orderId: this.searchForm.get('searchByOrderId').value,
      type: 'rma',
    };
    const searchByClientOrderId = {
      orderId: this.searchForm.get('searchByClientOrderId').value,
      type: 'clientOrder',
    };
    const formattedStartDate = this.getFormattedDate(this.startDate);
    const formattedEndDate = this.getFormattedDate(this.endDate);
    this.offSet = 0;
    if (
      !searchByOrderId.orderId &&
      !searchByClientOrderId.orderId &&
      (!this.startDate || !this.endDate)
    ) {
      this.searchForm
        .get('searchByOrderId')
        ?.setValidators(Validators.required);
      this.searchForm
        .get('searchByClientOrderId')
        ?.setValidators(Validators.required);
      this.searchForm.get('dateRange')?.setValidators(Validators.required);
      return;
    }
    if (searchByOrderId.orderId) {
      this.searchForm.get('searchByClientOrderId').reset();
      this.searchForm.get('searchByClientOrderId').setErrors(null);
      this.getReturnList(this.getRequestBody(searchByOrderId, null, null));
    } else if (searchByClientOrderId.orderId) {
      this.searchForm.get('searchByOrderId').reset();
      this.searchForm.get('searchByOrderId').setErrors(null);
      this.getReturnList(
        this.getRequestBody(searchByClientOrderId, null, null)
      );
    } else {
      this.getReturnList(
        this.getRequestBody(null, formattedStartDate, formattedEndDate)
      );
    }
    this.searchForm.get('dateRange').reset();
  }
  getRequestBody = (
    searchByOrderId: any,
    orderdatFrom: string,
    orderDateTo: string
  ) => {
    this.companyname = sessionStorage.getItem('companyname');
    this.selectedWareHouses = JSON.stringify(
      sessionStorage.getItem('selectedWareHouses')?.split(',')
    );
    if (searchByOrderId && searchByOrderId.type === 'rma') {
      sessionStorage.setItem('returnSearchOrder', searchByOrderId.orderId);
      if (this.companyname !== '') {
        searchByOrderId.orderId =
          searchByOrderId.orderId + '_' + this.companyname;
      }
      this.requestBody = {
        query:
          'query MyQuery {\n  allViewReturnListings(\n    first: ' +
          this.pageLimit +
          '\n    offset:  ' +
          this.offSet +
          '\n    filter: {facilityId: {in: ' +
          this.selectedWareHouses +
          '},companyName: {equalTo: "' +
          this.companyname +
          '"}, rma: {equalTo: "' +
          searchByOrderId.orderId +
          '"}}\n  ) {\n totalCount\n  pageInfo {\n endCursor\n hasNextPage\n hasPreviousPage\n startCursor\n }\n  nodes {\n  companyName\n returnsDate\n returnsQty\n rma\n skuDescription\n  skuId\n clientOrderId\n }\n  }\n}\n',
        variables: null,
        operationName: 'MyQuery',
      };
    } else if (searchByOrderId && searchByOrderId.type === 'clientOrder') {
      sessionStorage.setItem('returnClientOrderId', searchByOrderId.orderId);
      this.requestBody = {
        query:
          'query MyQuery {\n  allViewReturnListings(\n    first: ' +
          this.pageLimit +
          '\n    offset:  ' +
          this.offSet +
          '\n    filter: {facilityId: {in: ' +
          this.selectedWareHouses +
          '},companyName: {equalTo: "' +
          this.companyname +
          '"}, clientOrderId: {equalTo: "' +
          searchByOrderId.orderId.trim() +
          '"}}\n  ) {\n totalCount\n  pageInfo {\n endCursor\n hasNextPage\n hasPreviousPage\n startCursor\n }\n  nodes {\n  companyName\n returnsDate\n returnsQty\n rma\n skuDescription\n  skuId\n clientOrderId\n }\n  }\n}\n',
        variables: null,
        operationName: 'MyQuery',
      };
    } else {
      sessionStorage.setItem('returnOrderDateFrom', orderdatFrom);
      sessionStorage.setItem('returnOrderDateTo', orderDateTo);
      this.requestBody = {
        query:
          'query MyQuery {\n  allViewReturnListings(\n    first: ' +
          this.pageLimit +
          '\n    offset: ' +
          this.offSet +
          '\n    filter: { facilityId: {in: ' +
          this.selectedWareHouses +
          '},companyName: {equalTo: "' +
          this.companyname +
          '"}, returnsDate: {greaterThanOrEqualTo: "' +
          orderdatFrom +
          '", lessThanOrEqualTo: "' +
          orderDateTo +
          '"}}\n){\n totalCount\n pageInfo {\n endCursor\n  hasNextPage\n  hasPreviousPage\n startCursor\n }\n nodes { \n companyName\n returnsDate\n returnsQty\n rma\n  skuDescription\n  skuId\n clientOrderId \n }\n   }\n}\n',
        variables: null,
        operationName: 'MyQuery',
      };
    }
    return this.requestBody;
  };
  getReturnList(requestBody: any) {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
    this.subscription = this.returnService
      .getReturnList(requestBody)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (data) => {
          this.gridData.length = 0;
          this.summaryViewData = data.data.allViewReturnListings?.nodes;
          this.totalRecords = data.data.allViewReturnListings?.totalCount;
          this.onPageChange();
          this.populateGridData();
          this.cd.detectChanges();
        },
      });
  }
  populateGridData() {
    this.summaryViewData?.forEach((item: any) => {
      if (item && item?.rma !== undefined) {
        this.parentRowField = {
          rma: item?.rma.split('_')[0] || ''.trim(),
          clientOrderID: item.clientOrderId,
          returnsDate: item.returnsDate.split('T')[0],
          clientSKU: item.skuId,
          skuDescription: item.skuDescription,
          returnsQty: item.returnsQty.split('.')[0] || ''.trim(),
        };
        this.gridData.push({ data: this.parentRowField });
        this.gridData = [...this.gridData];
      }
    });
  }
  gridHeaderData() {
    this.headerColumns = returnHeader;
  }
  clearSelectedDate(event: any) {
    this.isClearSearchData = false;
    this.setFilterDate();
  }
  setFilterDate() {
    this.startDate = null;
    this.endDate = null;
  }
  clearSearchForm(formDirective) {
    this.submitted = false;
    this.isClearSearchData = true;
    this.totalCount = 0;
    this.gridData = [];
    this.gridData = [...this.gridData];
    this.totalRecords = 0;
    this.searchForm.get('searchByOrderId')?.setValidators(Validators.required);
    this.searchForm
      .get('searchByClientOrderId')
      ?.setValidators(Validators.required);
    this.searchForm.get('dateRange')?.setValidators(Validators.required);
    formDirective?.resetForm();
    this.cd.detectChanges();
  }
  onSelectedDates(event) {
    this.startDate = event[0];
    this.endDate = event[1];
    this.resetValidation();
    this.clearOtherFields('dateRange');
  }
  getFormattedDate(date: Date): string {
    if (!date) return null;
    const year = date.getFullYear();
    const month = ('0' + (date.getMonth() + 1)).slice(-2);
    const day = ('0' + date.getDate()).slice(-2);
    return `${year}-${month}-${day} 00:00:00`;
  }
  onStartDateSelected(startDate: Date) {
    this.startDate = startDate;
    const maxDate = new Date(startDate);
    maxDate.setDate(maxDate.getDate() + 31);
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    this.maxDateOfFirstSelectionDate = maxDate > today ? today : maxDate;
  }
  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }
}
