<div class="grid-outer">
  <div class="breadcrumb">
    <div class="breadcrumb-inner">
      <div class="icon-sm home-svg"></div>
      <div class="welcome-outer">
        <span class="welcome-text">{{ breadcrum_welcome }}</span>
        <span class="icon-md arrow-svg"></span>
      </div>
      <div>
        <ul class="list">
          <li>
            <a href="#">{{ breadcrum_return }}</a>
          </li>
        </ul>
      </div>
    </div>
  </div>
  <div class="page-title">
    <div class="text-left title-text">{{ titleName }}</div>
  </div>
  <div class="paginator">
    <div class="text-right">
      <rcp-paginator
        [first]="first"
        [rows]="rows"
        [totalRecords]="totalRecords"
        (onPageChangeEvent)="onPageChange($event)"
      ></rcp-paginator>
    </div>
  </div>
</div>
<div class="dropdowns">
  <form [formGroup]="searchForm" (ngSubmit)="searchFormSubmit(form2)">
    <div class="dropdowns-inner">
      <div class="inpux-item">
        <div class="input-box-with-icon">
          <input
            type="text"
            class="input-box-search"
            placeholder="Search by RMA"
            formControlName="searchByOrderId"
            #searchByOrderId
          />
          <span class="search-icon"></span>
          <div
            *ngIf="
              submitted &&
              searchForm.get('searchByOrderId')?.hasError('required')
            "
          >
            <small class="p-error">{{ validation_rma }}</small>
          </div>
        </div>
        <div class="input-box-with-icon">
          <input
            type="text"
            class="input-box-search"
            required
            placeholder="Search by Client Order ID"
            formControlName="searchByClientOrderId"
            #searchByClientOrderId
          />
          <span class="search-icon"></span>
          <div
            *ngIf="
              submitted &&
              searchForm.get('searchByClientOrderId')?.hasError('required')
            "
          >
            <small class="p-error">{{ validation_orderid }}</small>
          </div>
        </div>
        <div class="dateSeletor">
          <rcp-date-selector
            formGroupName="dateRange"
            [calenderDateInputPlaceholder]="placeholderValue"
            [isClearSearchData]="isClearSearchData"
            [maxDateOfFirstSelectionDate]="maxDateOfFirstSelectionDate"
            [isAddLast24HoursOption]="true"
            [isAddLast7DaysOption]="true"
            [isAddLast2WeeksOption]="true"
            [isAddLast30DaysOption]="true"
            (startDataEvent)="onStartDateSelected($event)"
            (dateSearchEvent)="onSelectedDates($event)"
            (clearSearchDataEvent)="clearSelectedDate($event)"
          >
          </rcp-date-selector>
          <div
            *ngIf="
              submitted && searchForm.get('dateRange')?.hasError('required')
            "
          >
            <small class="p-error">{{ validation_daterange }}</small>
          </div>
        </div>
      </div>
      <div class="apply-reset-button-container">
        <div class="apply-reset-button">
          <div class="button-inner">
            <button
              type="button"
              class="btn-secondary-lg"
              (click)="clearSearchForm(form2)"
            >
              {{ resetFilters }}
            </button>
            <button type="submit" class="btn-primary-lg">
              {{ applyFilters }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>
<div class="main-grid">
  <div class="text-center">
    <rcp-grid
      [gridData]="gridData"
      [headerColumns]="headerColumns"
      (pageChange)="onPageChange($event)"
      [first]="first"
      [rows]="rows"
      [passGridName]="passGridName"
    ></rcp-grid>
  </div>
</div>
