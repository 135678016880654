import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { forkJoin } from 'rxjs';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ReturnService {

  baseUrl = environment.baseUrl;
  cbrGraphUrl = environment.cbrGraphUrl;
  constructor(private http: HttpClient) { }
  getReturnList(data: any) {
    return this.http.post<any>(this.baseUrl + '/listingview',data,{ headers: { 'module-name': 'Return Listing' } });
  }
  getGraphData(body) {
    return this.http.post(this.cbrGraphUrl + '/return', body);
  }

  getGraphFilterDropdown(companyname) {
    const ordertypereq = {
      company_name: companyname,
      type: 'order_type'
    };
    const shippingmethodreq = {
      company_name: companyname,
      type: 'ReturnsReason'
    };
    const callOrderType = this.http.post(this.cbrGraphUrl + '/return/filter', ordertypereq);
    const callShippingMethod = this.http.post(this.cbrGraphUrl + '/return/filter', shippingmethodreq);
    return forkJoin([callOrderType, callShippingMethod]);
  }


}


